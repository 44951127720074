import { useEffect, useState, useContext, useMemo } from 'react'
import { Routes, Route, Outlet, Link } from 'react-router-dom'
import { useTheme } from '@mui/material/styles'
import { useInterval } from 'usehooks-ts'
import img2 from '../../media/small/2.jpeg'
import img3 from '../../media/small/3.jpeg'
import img4 from '../../media/small/4.jpeg'
import img5 from '../../media/small/5.jpeg'
import img6 from '../../media/small/6.jpeg'
import img7 from '../../media/small/7.jpeg'
import img8 from '../../media/small/8.jpeg'
import img9 from '../../media/small/9.jpeg'
import img10 from '../../media/small/16.jpeg'
import img11 from '../../media/small/17.jpeg'
import img12 from '../../media/small/18.jpeg'
import img13 from '../../media/small/19.jpeg'
import img14 from '../../media/small/20.jpeg'
import img15 from '../../media/small/21.jpeg'
import img16 from '../../media/small/22.jpeg'
import { motion } from 'framer-motion'

export const Sections = ({}) => {
  const theme = useTheme()
  const t = theme.palette.main
  const works = [
    {
      title: 'residential',
      imgs: [
        { src: img2 },
        { src: img3 },
        { src: img4 },
        { src: img5 },
        { src: img6 },
        { src: img7 },
        { src: img8 },
        { src: img9 },
        { src: img10 },
        { src: img11 },
        { src: img12 },
        { src: img13 },
        { src: img14 },
        { src: img15 },
        { src: img16 },
      ],
    },
    // {
    //   title: 'commercial',
    //   imgs: [{ src: img6 }, { src: img7 }, { src: img8 }, { src: img9 }],
    // },
  ]

  return (
    <div className={'stack v work'} style={{ background: '#000' }}>
      <h1 style={{ color: t.light }}>works</h1>
      {/* <div style={{ height: '70vh', width: '100vw' }}></div> */}
      {works.map((d, i) => (
        <section key={i}>
          <div className='work-type'>
            {/* {d.title && (
              <motion.div
                style={{ background: t.light, height: '.5em', width: '.5em' }}
                initial={{ scale: 0 }}
                whileInView={{ scale: 1 }}
                transition={{
                  type: 'spring',
                  stiffness: 300,
                  damping: 20,
                  delay: 0.5,
                }}
              />
            )} */}

            <h2 style={{ color: t.light }}>{d.title}</h2>
          </div>

          <>
            {d.imgs.reverse().map((d, i) => (
              <div className={'img2-wrap'}>
                <motion.img key={i} className='img2' src={d.src} />
              </div>
            ))}
          </>
        </section>
      ))}
    </div>
  )
}
