import { useEffect, useState, useContext, useMemo } from 'react'
import { Routes, Route, Outlet, Link } from 'react-router-dom'
import { useTheme } from '@mui/material/styles'
import { ReactComponent as Call } from '../../media/svg/call.svg'
import { ReactComponent as Pin } from '../../media/svg/pin.svg'
import { ReactComponent as Open } from '../../media/svg/open.svg'
import { ReactComponent as Cal } from '../../media/svg/cal.svg'

import { useInterval } from 'usehooks-ts'
import { Carousel } from './carousel'
import { Intro } from './intro'
import { Sections } from './sections'

import './home.css'

const Home = ({ data }) => {
  const theme = useTheme()
  const t = theme.palette.main

  return (
    <div>
      <Intro data={data} />
      <Sections />
    </div>
  )
}

export default Home
