import { useEffect, useState, useContext, useRef } from 'react'
import { Outlet, Link } from 'react-router-dom'
import { Button, Box, Stack } from '@mui/material'
import { motion } from 'framer-motion'
import { useTheme } from '@mui/material/styles'
import './head.css'
import { ReactComponent as Logo } from './rudys1.svg'
const Head = ({}) => {
  const theme = useTheme()

  return (
    <>
      <motion.header
      // initial={{ height: '100vh' }}
      // animate={{ height: 'auto' }}
      // transition={{ delay: 0.3, duration: 1.5, ease: 'easeInOut' }}
      >
        <motion.div
        // initial={{ filter: 'blur(4px)', opacity: 0 }}
        // animate={{ filter: 'blur(0px)', opacity: 1 }}
        // transition={{ duration: 0.5, ease: 'easeOut' }}
        >
          <Logo className={'logo'} />
        </motion.div>
        <motion.div className='town nicone'>Medford, MA</motion.div>
      </motion.header>

      <Outlet />
    </>
  )
}

export default Head
