import { createTheme } from '@mui/material/styles'

const theme = createTheme({
  palette: {
    mode: 'light',
    background: {
      default: '#FFFCF9',
    },
    main: {
      dark: '#160600',
      t1: '#736C69',
      t2: '#3D3937',
      light: '#FFFCF9',
      open: '#188030',
      closed: '#D93025',
    },
  },

  typography: {
    fontFamily: ['Roboto'],
  },
})

export default theme
