import { useState, useEffect } from 'react'
import { Routes, Route, useLocation } from 'react-router-dom'
import Home from './main/home/home'
import Head from './main/head/head'
import { ThemeProvider } from '@mui/material/styles'
import { CssBaseline } from '@mui/material'
import theme from './theme'
import { AnimatePresence } from 'framer-motion'
import './App.css'
import { initializeApp } from 'firebase/app'
// import functions from 'firebase-functions'
import axios from 'axios'

const placeid = 'ChIJI9igPOl244kRPtjaQNYTIyQ'
const App = ({}) => {
  const location = useLocation()
  const [data, setData] = useState(false)

  useEffect(() => {
    var config = {
      method: 'get',
      url: `https://corsproxy.io/?https://maps.googleapis.com/maps/api/place/details/json?place_id=${placeid}&key=${process.env.REACT_APP_MAPS}`,
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
      },
    }
    axios(config)
      .then((response) => {
        setData(response.data.result)
      })
      .catch((error) => setData('error'))
  }, [])

  return (
    <>
      <ThemeProvider theme={theme}>
        <AnimatePresence>
          <CssBaseline />
          <Routes location={location} key={location.pathname}>
            <Route path={'/'} element={<Head />}>
              <Route index element={<Home data={data} />} />
            </Route>
          </Routes>
        </AnimatePresence>
      </ThemeProvider>
    </>
  )
}

export default App
