import { useEffect, useState, useContext, useMemo } from 'react'
import { useTheme } from '@mui/material/styles'
import { AnimatePresence, motion, useAnimate } from 'framer-motion'
import img2 from '../../media/small/25.jpeg'
import img3 from '../../media/small/24.jpeg'
import img4 from '../../media/small/23.jpeg'
import img5 from '../../media/small/16.jpeg'
import img6 from '../../media/small/18.jpeg'
import img7 from '../../media/small/13.jpeg'
import img8 from '../../media/small/14.jpeg'
import img9 from '../../media/small/9.jpeg'

import { useInterval } from 'usehooks-ts'

const blur = {
  blur: { filter: 'blur(50px)', opacity: 1 },
  clear: { filter: 'blur(0px)', opacity: 1 },
}

const swipe = {
  left: { filter: 'blur(10px)', y: '100vw' },
  center: { filter: 'blur(0px)', y: 0 },
  right: { filter: 'blur(10px)', y: '100vw' },
}

const useAnim = (count) => {
  const [scope, animate] = useAnimate()

  useEffect(() => {
    animate([
      ['.next', { height: '0vw', top: '0px' }, { duration: 0 }],
      [
        '.next',
        { height: '90%', top: '0px' },
        { duration: 2, ease: 'easeInOut' },
      ],
    ])
  }, [count])

  return scope
}

export const Carousel = ({ user }) => {
  const theme = useTheme()
  let images = [img5, img6, img3, img4, img6, img7, img8, img9, img2]

  const [count, setCount] = useState(-1)

  useInterval(() => {
    setCount((count + 1) % (images.length - 1))
  }, 2000)

  const scope = useAnim(count)

  return (
    <AnimatePresence>
      <div className='img-wrap' ref={scope}>
        <div key={'b1'} className={'cur bottom'} style={{}}>
          <motion.img
            key={'img' + count + 'c'}
            className={'img1'}
            src={images[count]}
          />
        </div>
        <div key={'b2'} className={'next bottom'}>
          <motion.img
            key={'img' + count + 'n'}
            className={'img1'}
            src={images[count + 1]}
          />
        </div>
      </div>
    </AnimatePresence>
  )
}
