import { useEffect, useState } from 'react'
import { useTheme } from '@mui/material/styles'
import { ReactComponent as Call } from '../../media/svg/call.svg'
import { ReactComponent as Pin } from '../../media/svg/pin.svg'
import { ReactComponent as Open } from '../../media/svg/open.svg'
import { ReactComponent as Cal } from '../../media/svg/cal.svg'

import { useInterval } from 'usehooks-ts'
import { Carousel } from './carousel'
import { motion } from 'framer-motion'
import { useWindowSize } from 'usehooks-ts'
import { Desk } from './desk'

import { timeParse, timeFormat } from 'd3-time-format'

export const Intro = ({ data }) => {
  const theme = useTheme()
  const [open, setOpen] = useState(false)
  const { width, height } = useWindowSize()

  const t = theme.palette.main

  useEffect(() => {
    setOpen(data?.opening_hours?.open_now)
  }, [data])

  const [time, setTime] = useState(timeFormat('%x')(new Date()))

  return (
    <div className='home' style={{}}>
      <div className='intro'>
        {width < 860 ? (
          <>
            <div className='nicone years'>60 years of trusted service</div>
            <Carousel />
          </>
        ) : (
          <Desk />
        )}
        <div className={'about_info'}>
          <div className='infowrap'>
            <div className='info stack h'>
              <Cal className='pin' />
              <div className='hours'>
                Weekdays<br></br>9am - 5pm
              </div>
              {open && (
                <div className='stack h c open'>
                  <motion.div
                    className='dot'
                    style={{ background: open ? t.open : t.closed }}
                  />
                  <motion.div style={{ color: open ? t.open : t.closed }}>
                    {open ? 'open' : 'closed'}
                  </motion.div>
                </div>
              )}
            </div>
            <a
              href='https://goo.gl/maps/z4n2ajBqA4m9Rg447'
              style={{ color: t.dark }}
            >
              <div className='info stack h '>
                <Pin className='pin' />
                <div className='address'>
                  73 North St,<br></br> Medford, MA 02155
                </div>
                <Open className='open' />
              </div>
            </a>
            <div className='info'>
              <div style={{ textAlign: 'center', fontWeight: 600 }}>
                We provide free esimates.
              </div>
            </div>
            <a href='tel:6176253646' style={{ color: t.light }}>
              <div className='call stack h' style={{ background: t.dark }}>
                <Call className='phone' style={{ fill: t.light }} />
                <div className={'number'}>617-625-3646</div>
              </div>
            </a>
          </div>

          <div className='aboutwrap'>
            <div className='nicone years2' style={{ color: t.t1 }}>
              60 years of quality service
            </div>
            <div className='about barlow' style={{ color: t.t2 }}>
              Family owned brick and mortar serving Greater Boston. With a focus
              on residential, commercial and marine upholstery, we revive
              timeless designs and refurbish worn pieces. Your furniture is in
              good hands.
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
